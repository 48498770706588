import { styled, Box, Button, Stack, Table } from '@a1s/ui';
import { useQuery, QueryResult } from '@apollo/client';
import { loader } from 'graphql.macro';
import hash from 'object-hash';
import React, { useEffect, useMemo, ComponentProps, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { getUniqueRecipientsAsString } from '../../../../shared/dataTypeAndUtils';
import { canMarkAsFalsePositive, fixMessagesTs, toVariables } from '../../../lib';
import { Dispositions, SearchResultRow } from '../../../types';
import {
  MetaCell,
  ModalHeader,
  ReasonCell,
  ReleaseButton,
  RenderNoData,
  ReportLinkButton,
  RetractButton,
  RowWrapper,
  SectionHeader,
  SimilarMessages,
  StatusCell,
  ThreatCell,
} from '../../../ui';

import { useSearchContext } from 'screens/Search/lib/searchContext';
import { Scrollable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface ResultsProps {
  checked?: Array<SearchResultRow['messageId']>;

  collapsed: boolean;

  // NOTE: this will probably need to be reworked so the event handler can get the row ID
  onPressViewButton?: RowProps['onPressViewButton'];

  // eslint-disable-next-line  no-unused-vars
  onRowCheck?(checked: Array<SearchResultRow['messageId']>): void;

  // eslint-disable-next-line no-unused-vars
  onSearchFinished?(counts: ComponentProps<typeof SectionHeader>['searchCounts']): void;

  // eslint-disable-next-line no-unused-vars
  onSearchStart?(): void;

  /**
   * The string and date to run the search query on.
   */
  search: ComponentProps<typeof ModalHeader>['params'];

  /**
   * The message ID of the message details currently selected
   */
  selectedMessageId?: string;
}

export function Results({
  checked = [],
  collapsed,
  onPressViewButton,
  onRowCheck,
  onSearchFinished,
  onSearchStart,
  search,
  selectedMessageId,
}: ResultsProps) {
  const { clawbackEnabled } = useSearchContext();
  const { data, error, loading, fetchMore } = useRemoteData(search);

  const effectDependency = hash({ data });
  useEffect(() => {
    if (!onSearchFinished) return;
    if (!data?.length) {
      onSearchFinished({ total: 0, detections: 0 });

      return;
    }

    const detections = data.filter((d) => d.finalDisposition.toLowerCase() !== 'none');
    onSearchFinished({ total: data.length, detections: detections.length });
  }, [effectDependency]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onSearchStart && loading === true) onSearchStart();
  }, [loading, onSearchStart]);

  function handleCheck(id: string, isChecked: boolean) {
    if (!onRowCheck) return;
    if (isChecked) {
      onRowCheck([...checked, id]);
    } else {
      onRowCheck(checked.filter((i) => i !== id));
    }
  }

  function handleEnter() {
    if (data?.length && fetchMore) fetchMore();
  }

  const hasData = data && data.length > 0 && !loading && !error;

  return (
    <Box css={{ flexGrow: 1, height: '100%', minWidth: 470, position: 'relative' }}>
      <Scrollable>
        <Table css={{ minWidth: collapsed ? 'auto' : 1280 }}>
          <tbody>
            {hasData && !loading ? (
              <>
                {data.map((row) => (
                  <Row
                    checked={checked.includes(row.messageId)}
                    collapsed={collapsed}
                    clawbackFeatureEnabled={clawbackEnabled}
                    data={row}
                    key={row.id}
                    onCheck={handleCheck}
                    onPressViewButton={onPressViewButton}
                    selectedMessageId={selectedMessageId}
                  />
                ))}
                <tr>
                  <td colSpan={5}>
                    <Waypoint onEnter={handleEnter} />
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={5}>
                  <RenderNoData loading={loading} term={search.searchTerm} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Scrollable>
    </Box>
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface BlankCellProps {
  hidden?: boolean;
}

function BlankCell({ hidden }: BlankCellProps) {
  return <Table.Cell border="none" css={{ display: hidden === true ? 'none' : undefined }} />;
}

interface RowProps {
  checked: ComponentProps<typeof MetaCell>['checked'];
  clawbackFeatureEnabled?: boolean;
  collapsed: ResultsProps['collapsed'];
  data: SearchResultRow;
  onCheck: ComponentProps<typeof MetaCell>['onCheck'];
  onPressViewButton?: (details: SearchResultRow) => void; // eslint-disable-line no-unused-vars
  selectedMessageId?: string;
}

function Row({
  checked,
  clawbackFeatureEnabled,
  collapsed,
  data,
  onCheck,
  onPressViewButton,
  selectedMessageId,
}: RowProps) {
  const { retractEnabled, userPermitted } = useSearchContext();
  const { t } = useTranslation('unisearch');

  const {
    bccRecipient,
    cc,
    clientRecipients,
    edfHash,
    envelopeTo,
    findings,
    id,
    isQuarantined,
    messageId,
    phishSubmission,
    storedAt,
    threatCategories = [],
    validation,
    to,
  } = data;

  const handlePressView = () => {
    if (onPressViewButton) onPressViewButton(data);
  };

  function handleRowClick(e: MouseEvent<HTMLElement>) {
    e.stopPropagation();
    if (collapsed && onPressViewButton) onPressViewButton(data);
  }

  const recipients = getUniqueRecipientsAsString(bccRecipient, cc, envelopeTo, to);

  return (
    <RowWrapper collapsed={collapsed} onClick={handleRowClick} selected={selectedMessageId === id}>
      <MetaCell
        checked={checked}
        from={data.from}
        messageId={messageId}
        onCheck={onCheck}
        subject={data.subject}
        timestamp={data.ts}
        to={recipients}
      />
      <StatusCell
        disposition={data.finalDisposition}
        isQuarantined={isQuarantined}
        redressedActions={data.redressedActions}
        truncated={collapsed}
        validation={validation}
      />
      <RenderThreatCell collapsed={collapsed} dispositionType={data.finalDisposition} findings={threatCategories} />
      <RenderReasonCell collapsed={collapsed} detectionReasons={findings} />
      <Table.Cell border="none" css={{ display: collapsed === true ? 'none' : undefined }}>
        <Stack align="end" css={{ height: '100%' }} gap={2}>
          <ButtonWrapper>
            {userPermitted && (
              <>
                {isQuarantined && <ReleaseButton releaseParams={[{ clientRecipients, storedAt }]} />}
                {!isQuarantined && !phishSubmission && retractEnabled && (
                  <RetractButton
                    clawbackFeatureEnabled={clawbackFeatureEnabled}
                    retractParams={[{ clientRecipients, messageId }]}
                  />
                )}
                <Button onPress={handlePressView}>{t('view')}</Button>
              </>
            )}
          </ButtonWrapper>
          <StackWithWaypointBuster align="end" css={{ flexGrow: 1 }} justify="space-between">
            {edfHash && <SimilarMessages edfHash={edfHash} searchType="detection-only" />}
            <Stack align="end" css={{ flexGrow: 1 }} justify="end">
              {data && userPermitted && (
                <ReportLinkButton
                  data={data}
                  kind={canMarkAsFalsePositive(data.finalDisposition) ? 'false-positive' : 'false-negative'}
                />
              )}
            </Stack>
          </StackWithWaypointBuster>
        </Stack>
      </Table.Cell>
    </RowWrapper>
  );
}

interface RenderThreatCellProps {
  collapsed: ResultsProps['collapsed'];
  dispositionType: Dispositions;
  findings: string[];
}

function RenderThreatCell({ collapsed, dispositionType, findings }: RenderThreatCellProps) {
  if (dispositionType !== 'NONE') {
    return <ThreatCell findings={findings} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

interface ReasonCellProps {
  collapsed: ResultsProps['collapsed'];
  detectionReasons: SearchResultRow['findings'];
}

function RenderReasonCell({ collapsed, detectionReasons = [] }: ReasonCellProps) {
  if (detectionReasons.length > 0) {
    return <ReasonCell data={detectionReasons} hidden={collapsed} />;
  }

  return <BlankCell hidden={collapsed} />;
}

//
// Private hooks
// -------------------------------------------------------------------------------------------------

const query = loader('../queries/search.graphql');

interface HookResult {
  /**
   * The data that has been returned from the API
   */
  data?: SearchResultRow[];

  /**
   * If there is a problem loading the data, the error information will be available as an error object
   */
  error: QueryResult['error'] | null;

  /**
   * Returns a function that can be used to fetch more data
   */
  fetchMore?(): void;

  /**
   * Returns true if the data is currently being loaded
   */
  loading: boolean;
}

function useRemoteData(search: ResultsProps['search']): HookResult {
  const date = useMemo(() => new Date(), []);

  const { data, error, fetchMore, loading } = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: toVariables(date, search),
  });

  if (error) return { data: undefined, error, fetchMore: undefined, loading: false };
  if (!data?.unisearchResults?.messages) return { data: undefined, error: null, fetchMore: undefined, loading };

  function more() {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return {
          unisearchResults: {
            error: null,
            // @ts-ignore
            messages: [...(prev.unisearchResults.messages || []), ...(fetchMoreResult.unisearchResults.messages || [])],
            __typename: 'UnisearchDetectionSearchResults',
          },
        };
      },
      variables: { limit: 50, offset: data?.unisearchResults?.messages.length },
    });
  }
  const results = fixMessagesTs(data.unisearchResults.messages);

  return { data: results, error, fetchMore: more, loading };
}

//
// Styled components
// -------------------------------------------------------------------------------------------------

const ButtonWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  float: 'right',
  gap: '$2',
  justifyContent: 'end',
  maxWidth: 195,
  width: '100%',
});

const StackWithWaypointBuster = styled(Stack, {
  // This little bit of CSS removes the `Waypoint` component inside of the `SimilarMessages`
  // from the flexbox layout while still letting it be considered a visible element bvy the
  // browser in a way that adding `display: none` or `visibility: hiddern` would not. The
  // reason for this is that while it was still in the flexbox layout, it was adding extra
  // padding on the element because flexbox as still rendering a gap inbetween the 0px
  // element that the `Waypoint` component renders.
  '& span[style]': {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap;',
    width: '1px',
  },
});
