//  @flow

import parser from 'fast-xml-parser';
import isCidr from 'is-cidr';
import isIp from 'is-ip';
import isValidDomain from 'is-valid-domain';
import { escapeRegExp } from 'lodash';

const emailRegexp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

// eslint-disable-next-line no-useless-escape
const httpsUrlRegexp = /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/i;
const portCheckRegEx = new RegExp(/^\d{1,5}$/);
const twentyFourHourPatt = new RegExp(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/);
const urlRegexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/i;
const wholeNumberPatt = new RegExp(/^[0-9]\d*$/);

// Use like this:  if (!validations.presence(someValue)) , eg
const validations = {
  anyTrue(vals: Array<any>) {
    return vals.some((val) => val);
  },

  cidr(value: string): boolean {
    if (!value) {
      return true;
    }
    // cidr returns 4 for v4 and 6 for v6 or 0 if invalid
    return isCidr(value) !== 0;
  },

  // Usage if (!validations.disallow(['!'], value))
  // `notAllowed` is an array of characters not allowed to appear anywhere in `value`
  disallow(notAllowed: string[], value: string): boolean {
    if (!Array.isArray(notAllowed)) throw new Error('`notAllowed` must be an array.');

    if (!value) {
      return true;
    }

    const notAllowedForRegExp = notAllowed.map((char) => escapeRegExp(char)).join('|');
    const regExp = `^(?!.*(${notAllowedForRegExp}).*).*$`;

    return new RegExp(regExp).test(value);
  },

  disallowCommasBetweenEntries(value: string): boolean {
    if (!value) {
      return true;
    }

    return !!value.match(/((,\s[a-z]{1,61})|(,[a-z]{1,61}))/g);
  },

  disallowWhitespace(value: string): boolean {
    if (!value) {
      return true;
    }

    return !!value.match(/([^\S*$])/g);
  },

  domain(value: string): boolean {
    if (!value) {
      return true;
    }
    // wildcard allows for '.*jira.com'
    return isValidDomain(value, { subdomain: true, wildcard: true });
  },

  email(value: string): boolean {
    if (!value) {
      return true;
    }

    return emailRegexp.test(value);
  },

  ensureClosedTags(value: string): boolean {
    if (!value) return true;

    const stack = [];
    const symbols = ['[', ']', '{', '}', '(', ')', '<', '>'];
    const matchedSymbols = {
      ']': '[',
      '}': '{',
      ')': '(',
      '>': '<',
    };

    value.split('').forEach((char) => {
      if (symbols.indexOf(char) !== -1) {
        if (!stack.length) {
          stack.push(char);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (matchedSymbols[char] === stack[stack.length - 1]) {
            stack.pop();
          } else {
            stack.push(char);
          }
        }
      }
    });

    return stack.length === 0;
  },

  httpsUrl(value: string): boolean {
    if (!value) {
      return true;
    }

    return httpsUrlRegexp.test(value);
  },

  ip(value: string): boolean {
    if (!value) {
      return true;
    }

    return isIp(value);
  },

  // Validates whether `value` is one of true, "true", false, "false"
  // A falsy `value` should return false, as it's not really a boolean.
  isBoolean(value: any): boolean {
    // It's important to use String() here because without it
    // `isBoolean(0)` won't make it past this point and this will return true instead of false
    if (!String(value)) {
      return true;
    }

    const trimmed = String(value).trim().toLowerCase();

    return trimmed === 'false' || trimmed === 'true';
  },

  likeCfAccountId(id: string): boolean {
    // alphanumeric characters and digits only like: "020c8779d6ac99685f056a2729b3c2b1"
    return !!id.match(/(?=[a-z0-9]*$)/g) && id.match(/(?=[a-z0-9]*$)/g)?.length === 33;
  },

  matches(value: string, second: string): boolean {
    if (!value) {
      return true;
    }

    return value === second;
  },

  maxLength(max: number, value: string): boolean {
    if (!value) {
      return true;
    }

    return value.length <= max;
  },

  minLength(min: number, value: string): boolean {
    if (!value) {
      return true;
    }

    return value.length >= min;
  },

  minWords(value: string, wordCount: number = 1): boolean {
    if (!value) {
      return true;
    }
    return !(value.split(' ').filter(String).length < wordCount);
  },

  oneOrTheOther(val1: boolean, val2: boolean) {
    return val1 || val2;
  },

  presence(value: ?string | ?number): boolean {
    if (!value) {
      return false;
    }
    if (typeof value === 'string') {
      return !!value.trim();
    }

    return true;
  },

  protocolOrSubdomain(value: string): boolean {
    if (!value) {
      return true;
    }
    return !!(value.match(/(www)/g) || value.match(/(http)/g));
  },

  siem(siem: string): boolean {
    // Eg https://siem-tests.area1security.com:8088/services/collector/event
    const [scheme, domainPortAndPath] = siem.split('https://');
    // a split of a valid url will be ['', siem-tests.area1security.com:8088/services/collector/event]
    if (scheme !== '') return false;

    const [domainWithPort] = domainPortAndPath.split('/');
    // results in siem-tests.area1security.com:8088
    // check for port. there can only be 0 or 1 port
    const [domain, ...port] = domainWithPort.split(':');
    if (port.length > 1) return false;
    if (port.length) {
      if (!portCheckRegEx.test(port[0])) return false;
    }

    return isValidDomain(domain);
  },

  timestamp(value: string): boolean {
    if (!value) {
      return true;
    }

    return twentyFourHourPatt.test(value);
  },

  uniqueness(id: number, value: string, existingIdAndValue: any[]): boolean {
    const existingValues = existingIdAndValue.reduce((m, e) => {
      const [existingId, existingValue] = e;
      if (id !== existingId) m.push(existingValue.trim().toLowerCase());
      return m;
    }, []);

    return !existingValues.includes(value.trim().toLowerCase());
  },

  url(value: string): boolean {
    if (!value) {
      return true;
    }

    return urlRegexp.test(value);
  },

  validRegex(value: string): boolean {
    if (!value) {
      return true;
    }

    const tagsClosed = this.ensureClosedTags(value);

    if (!tagsClosed) {
      return false;
    }

    try {
      // eslint-disable-next-line no-new
      new RegExp(value);
    } catch {
      return false;
    }

    return true;
  },

  validXml(input: string): boolean {
    // example invalid return -> {err: {code: "InvalidChar", msg: "char h is not expected ."}
    // example valid return -> true
    return !parser.validate(input).err;
  },

  within(start: number, stop: number, value: string | number): boolean {
    if (value === '') {
      return true;
    }

    return Number(value) >= Number(start) && Number(value) <= Number(stop);
  },

  wholeNumber(value: number | string): boolean {
    return wholeNumberPatt.test(String(value));
  },
};

export default validations;
