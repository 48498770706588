import { Box, Button, Cluster, HeaderCell, Radio, Stack, Table } from '@a1s/ui';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SearchResultRow } from '../../types';

import {
  useReportedAlerts,
  useReportFalsePositiveMutation,
  useReportFalseNegativeMutation,
} from 'screens/Search/lib/hooks';
import type { Dispositions } from 'screens/Search/types';
import { CloseButton, Dialog, Text } from 'ui-new';
import ConditionalRender from 'ui/atoms/ConditionalRender';

type FailedMessageType = {
  alertId: string;
  expectedDisposition: Dispositions | 'NONE';
};

interface FalsePositiveDialogProps {
  data: SearchResultRow[];
  // eslint-disable-next-line
  onClose: () => void;
  visible: boolean;
}

const DISPOSITIONS: Dispositions[] = ['BULK', 'MALICIOUS', 'NONE', 'SPAM', 'SPOOF', 'SUSPICIOUS'];

export default function FalsePositiveDialog({ data, onClose, visible }: FalsePositiveDialogProps) {
  // eslint-disable-next-line
  const [_, appendId] = useReportedAlerts();
  const { t } = useTranslation('unisearch');
  const [disposition, setDiposition] = useState<Dispositions | 'NONE'>('NONE');
  const [failedMessages, setFailedMessages] = useState<FailedMessageType[]>([]);
  const [submitting, setSubmitting] = useState(false);

  const [reportFalsePositive, { loading: reportFpLoading }] = useReportFalsePositiveMutation();
  const [reportFalseNegative, { loading: reportFnLoading }] = useReportFalseNegativeMutation() || [null, {}]; // Jest doesn't like this hook !?!

  function handleClose() {
    setDiposition('NONE');
    setFailedMessages([]);
    if (typeof onClose === 'function') onClose();
  }

  const failedSubmissions: FailedMessageType[] = [];

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setSubmitting(true);
    await Promise.all(
      data.map(async (input: SearchResultRow, index) => {
        try {
          // Per https://jira.cfdata.org/browse/CUSTESC-42998, some detections don't have an alert_id present.
          // Those must be reported using the false negative API endpoint with an `expectedDisposition` of 'NONE'
          if (input?.alertId) {
            const payload = {
              expectedDisposition: disposition,
              alertId: input.alertId,
            };
            await reportFalsePositive({
              variables: { input: payload },
            });
          } else {
            const payload = {
              expectedDisposition: 'NONE' as Dispositions,
              messageId: input.messageId,
              postfixId: input.postfixIdent,
              recipientEmails: input.clientRecipients,
            };
            await reportFalseNegative({ variables: { input: payload } });
          }

          appendId(input.alertId || input.messageId);

          if (data.length - 1 === index) {
            handleClose();
          }
        } catch (error) {
          failedSubmissions.push({ alertId: input.alertId || input.messageId, expectedDisposition: disposition });

          if (data.length - 1 === index) {
            setFailedMessages(failedSubmissions);
          }

          // check Sentry for potential errors
          // eslint-disable-next-line no-console
          console.error('Error reporting false-positive:', error);
        }
      })
    );
    setSubmitting(false);
  }

  if (!visible) return null;

  return (
    <Dialog visible={visible} onClose={handleClose}>
      <Box p>
        <Box css={{ float: 'right' }}>
          <CloseButton onPress={handleClose} small />
        </Box>

        <Box pt="5" pr="3">
          <Stack>
            <ConditionalRender
              condition={failedMessages.length > 0}
              fallback={
                <>
                  <Box pb="5">
                    <Stack gap="4">
                      <Text color="$orange500" size="lg" transform="uppercase" weight="semibold">
                        {t('reportEmailFP')}
                      </Text>

                      <Text as="p" color="$gray500">
                        {t('falsePositiveReason')}
                      </Text>
                    </Stack>
                  </Box>
                  <Box pb="6">
                    <Stack gap="2">
                      {DISPOSITIONS.map((d: Dispositions) => (
                        <Box key={d}>
                          <Radio
                            checked={disposition === d}
                            css={{ fontFamily: 'sans' }}
                            id={d}
                            label={t(d)}
                            onChange={() => setDiposition(d)}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </Box>
                </>
              }
            >
              <Box p>
                <Text as="p" color="$red500">
                  {t('errorReportingFP')}
                </Text>

                <Table>
                  <Table.Header>
                    <HeaderCell css={{ fontSize: 'small' }}>{t('Alert Id')}</HeaderCell>
                    <HeaderCell css={{ fontSize: 'small' }}>{t('Expected Disposition')}</HeaderCell>
                  </Table.Header>
                  <Table.Main>
                    {failedMessages.map((message) => (
                      <Table.Row key={message.alertId}>
                        <Table.Cell>
                          <Text>{message.alertId}</Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Text>{message.expectedDisposition}</Text>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Main>
                </Table>
              </Box>
            </ConditionalRender>

            <Stack align="end">
              <Cluster gap>
                <Button onPress={handleClose}>{t('Close')}</Button>
                <ConditionalRender condition={failedMessages.length < 1}>
                  <Button
                    disabled={reportFnLoading || reportFpLoading || submitting}
                    appearance="primary"
                    onPress={handleSubmit}
                  >
                    {t('reportFalsePositive')}
                  </Button>
                </ConditionalRender>
              </Cluster>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}
