import { Cluster, Stack, Table, Text } from '@a1s/ui';
import { format, parseISO } from 'date-fns';
import React, { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { Checkbox, MaxHeight } from '..';
import { SearchResultRow } from '../../types';

import { useSearchContext } from 'screens/Search/lib/searchContext';
import { InlineTable } from 'ui-new';

//
// Main component
// -------------------------------------------------------------------------------------------------

interface MetaCellProps {
  checked?: boolean;
  from: SearchResultRow['from'];
  messageId: SearchResultRow['messageId'];
  subject: SearchResultRow['subject'];

  // eslint-disable-next-line  no-unused-vars
  onCheck?(messageId: SearchResultRow['messageId'], checked: boolean): void;

  timestamp: SearchResultRow['ts'];
  to?: string;
}

/**
 * Displays email metadata about a search result.
 */
export function MetaCell({ checked = false, from, messageId, onCheck, subject, timestamp, to }: MetaCellProps) {
  const { userPermitted } = useSearchContext();
  const { t } = useTranslation('unisearch');

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (onCheck) onCheck(messageId, event.currentTarget.checked);
  }

  return (
    <Table.Cell border="none" css={{ width: 325 }}>
      <Cluster css={{ pr: '$2' }} gap="3" nowrap>
        {userPermitted && <Checkbox checked={checked} onChange={handleChange} data-row-checkbox={messageId} />}
        <Stack>
          <Text as="p" color="$gray500" font="sans" size="sm" stretch="ultraCondensed" transform="uppercase">
            {format(parseISO(timestamp), t('const:formats.dateLongWithTimezone'))}
          </Text>

          <InlineTable gap="$1">
            <tbody>
              <Data label={t('from')} value={from} />
              <RecipientsData label={t('to')} value={to || t('undisclosedRecipients')} />
              <Data label={t('sub')} nowrap={false} value={subject} />
            </tbody>
          </InlineTable>
        </Stack>
      </Cluster>
    </Table.Cell>
  );
}

//
// Public helper functions
// -------------------------------------------------------------------------------------------------

/**
 * This function encapsulates the hacky behavior that works around how the search results were
 * architected (misarchitected?). Instead of passing callbacks around and dealing with a complicated
 * React context object, the "Select All" button can use this function to get all of the IDs of the
 * the messages displayed. If you find yourself using this function for something other than the
 * "select all" functionality, you really should consider refactoring this code.
 */
export function getIdsForCheckboxes() {
  const rows = document.querySelectorAll('[data-row-checkbox]');

  const ids: string[] = [];
  rows.forEach((r: any) => ids.push(r.dataset.rowCheckbox));
  return ids;
}

//
// Private components
// -------------------------------------------------------------------------------------------------

interface DataProps {
  label: string;
  nowrap?: boolean;
  value?: string;
}

function Data({ label, nowrap, value = '' }: DataProps) {
  return (
    <InlineTable.Row>
      <RenderLabel label={label} />
      <InlineTable.Cell>
        <RenderValue nowrap={nowrap} value={value} />
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}

interface RenderLabelProps {
  label: string;
}

function RenderLabel({ label }: RenderLabelProps) {
  return (
    <InlineTable.Cell>
      <Text
        as="div"
        color="$gray800"
        font="sans"
        size="sm"
        stretch="ultraCondensed"
        transform="uppercase"
        weight="bold"
      >
        {label}:
      </Text>
    </InlineTable.Cell>
  );
}

interface RenderValueProps {
  nowrap?: boolean;
  value: string;
}

function RenderValue({ nowrap = true, value }: RenderValueProps) {
  return (
    <Text
      as="div"
      color="$gray800"
      css={{ maxWidth: 200, wordBreak: !nowrap ? 'break-word' : 'unset' }}
      font="sans"
      nowrap={nowrap}
      size="sm"
      stretch="ultraCondensed"
      weight="light"
    >
      {value}
    </Text>
  );
}

interface RecipientsDataProps {
  label: string;
  value: string;
}

function RecipientsData({ label, value }: RecipientsDataProps) {
  return (
    <InlineTable.Row>
      <RenderLabel label={label} />
      <InlineTable.Cell>
        <MaxHeight height={value && value.length > 1 ? 45 : 21}>
          <RenderValue value={value} />
        </MaxHeight>
      </InlineTable.Cell>
    </InlineTable.Row>
  );
}
