// @flow
// $FlowFixMe
import { styled as stitches } from '@a1s/ui';
import { get } from 'lodash';
import { rem } from 'polished';
// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
// $FlowFixMe
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import QRCode from '../QRCode';

import Button from 'ui/atoms/Button';
import ConditionalRender from 'ui/atoms/ConditionalRender';
import Form from 'ui/atoms/Form';
import Label from 'ui/atoms/Label';
import Link from 'ui/atoms/Link';
import PaddedContent from 'ui/atoms/PaddedContent';
import Rule from 'ui/atoms/Rule';
import TextInput from 'ui/atoms/TextInput';
import Title from 'ui/atoms/Title';
import Content from 'ui/molecules/Content';
import ErrorMessage from 'ui/molecules/ErrorMessage';
import Field from 'ui/molecules/Field';
import Info from 'ui/molecules/Info';
import TextField from 'ui/molecules/TextField';
import PasswordInput from 'ui/organisms/PasswordInput';
import validations from 'utils/validations';

//
// Styled components
// -------------------------------------------------------------------------------------------------

const Centered = styled.div`
  ${(p) =>
    !p.disabled &&
    css`
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
    `};
`;

const Footer = styled.footer`
  align-items: center;
  justify-content: space-between;
  margin-top: ${(p) => rem(p.theme.spacing.md)};

  div {
    display: flex;
    justify-content: flex-end;
  }

  p {
    margin-top: ${(p) => rem(p.theme.spacing.md)};
    text-align: right;
  }

  #forgotPassword {
    justify-content: right;
    text-align: right;
  }
`;

const StyledForm = styled(Form)`
  width: ${rem(400)};

  label {
    max-width: none !important;
  }
`;

const StyledP = styled.p`
  white-space: break-spaces;
  width: ${rem(250)};
`;

const FormTitle = styled(Title)`
  margin-bottom: ${(p) => rem(p.theme.spacing.lg)};
  text-align: center;
`;

const StyledField = styled(Field)`
  padding-left: 0px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white} !important;
  text-decoration: underline !important;
`;

const VerificationLabel = styled(Label)`
  white-space: nowrap;
`;

//
// Main component
// -------------------------------------------------------------------------------------------------

type CheckEmailCallback = (email: string) => void | Promise<void>;
type LoginCallback = (email: string, password: string, tfaCode: ?string) => void | Promise<void>;
type SsoLoginCallback = (email: string) => void | Promise<void>;

type Props = {
  checkEmailError: boolean,
  emailVerified: boolean,
  enforceSaml?: boolean,
  isLoading?: boolean,
  isLoadingSaml?: boolean,
  loginErrorKey?: string,
  onCheckEmail?: CheckEmailCallback,
  onLogin?: LoginCallback,
  onSsoLogin?: SsoLoginCallback,
  samlEnabled?: boolean,
  showOnboarding?: boolean,
  tfaEnabled?: boolean,
  urlEmail?: string,
};

export default function LoginForm(props: Props) {
  const {
    checkEmailError,
    emailVerified,
    enforceSaml,
    isLoading,
    isLoadingSaml,
    loginErrorKey,
    onCheckEmail,
    onLogin,
    onSsoLogin,
    samlEnabled,
    showOnboarding = false,
    tfaEnabled = true,
    urlEmail,
  } = props;

  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tfaCode, setTfaCode] = useState('');

  const { t } = useTranslation('loginPage');

  const tfaResetEmail = get(location, 'state.tfa_reset_email', false);
  const isCloudflareLink = get(location, 'state.cloudflare_link', false);
  const isPreviewSite = window.location.host.startsWith('preview');

  useEffect(() => {
    if (urlEmail && !emailVerified && onCheckEmail) {
      onCheckEmail(urlEmail);
      setEmail(urlEmail);
    }
  }, [emailVerified, onCheckEmail, urlEmail]);

  function isLoginDisabled() {
    const formFieldsValid = validate(email || tfaResetEmail, password, tfaCode, tfaEnabled || false);

    if (isLoading) return true;
    return !formFieldsValid;
  }

  function handleSubmit(event: SyntheticInputEvent<HTMLInputElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (!emailVerified && onCheckEmail) {
      onCheckEmail(email);
    } else if (onLogin) {
      onLogin(password, tfaCode);
    }
  }
  const loginDisabled = isLoginDisabled();

  const loginErrorMessage = (errorMessage: typeof undefined | string) => {
    if (errorMessage === 'passwordExpiredError') {
      return (
        <Trans i18nKey="passwordExpiredError" ns="loginPage">
          Your password has expired.{' '}
          <StyledLink dataTestId="password-expired-link" to="/users/login/forgot-password">
            Reset your password{' '}
          </StyledLink>{' '}
          to regain access to the portal.
        </Trans>
      );
    }

    if (errorMessage === 'demoTrialEndedError') {
      return [
        <Trans i18nKey="demoTrialEndedError" ns="loginPage">
          <p>Thank you for visiting Cloudflare Area 1 Security’s interactive self-guided demonstration.</p>
          <p>
            It’s been 14 days already! Your trial period has expired, but if you want to regain access, just email us at{' '}
            <Link external style={{ color: '#fff', textDecoration: 'underline' }} to="mailto:support@area1security.com">
              support@area1security.com
            </Link>
            .
          </p>
          <p>
            Better yet, request a complimentary trial for your own organization — with setup in less than five minutes.
            The data in this simulated demo is not based on actual Area 1 customer data, but we can show you the real
            phishing attacks that are slipping past your current defenses, with a complimentary Phishing Risk
            Assessment.
          </p>
          <p>
            Learn more{' '}
            <Link
              external
              to="https://www.area1security.com/phishing-risk-assessment/?utm_medium=self-guided-demo&utm_source=in-app-notification&utm_term=na&utm_content=na&utm_campaign=self-guided-demo"
              rel="nofollow"
              style={{ color: '#fff', textDecoration: 'underline' }}
              target="_blank"
            >
              here
            </Link>
            .
          </p>
        </Trans>,
      ];
    }

    return t(errorMessage);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      {!enforceSaml && isCloudflareLink ? <LinkCloudflareInstructions /> : <FormTitle>{t('signIn')}</FormTitle>}

      <ConditionalRender condition={(!!loginErrorKey || !!checkEmailError) && !isLoading}>
        <PaddedContent pushTop="lg">
          <ErrorMessage>
            {checkEmailError ? t('passwordResetInProgress') : loginErrorMessage(loginErrorKey)}
          </ErrorMessage>
        </PaddedContent>
      </ConditionalRender>

      <ConditionalRender condition={!emailVerified || checkEmailError}>
        <TextField
          dataTestId="text-field-email"
          label={t('emailLabel')}
          large
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          stacked
          value={email}
        />
        <Footer>
          <Button
            dataTestId="button-next"
            disabled={email === '' || !validations.email(email)}
            icon={isLoading && 'spinner'}
            type="submit"
          >
            {t('common:next')}
          </Button>
          <p id="forgotPassword">
            <Link to={{ pathname: '/users/login/forgot-password', state: { email: encodeURIComponent(email) } }}>
              {t('forgotPassword')}
            </Link>
          </p>
        </Footer>
        <ConditionalRender condition={!isPreviewSite}>
          <ConditionalRender condition={!isCloudflareLink}>
            <Rule margin="lg" />
            <SignInWithCloudflare disabled={!!enforceSaml} />
          </ConditionalRender>
        </ConditionalRender>
      </ConditionalRender>

      <ConditionalRender condition={emailVerified && !checkEmailError}>
        <ConditionalRender condition={!enforceSaml}>
          <StyledField stacked>
            <Label htmlFor="passwordLabel">{t('passwordLabel')}</Label>
            <PasswordInput
              large
              name="password"
              onChange={({ target }) => setPassword(target.value)}
              value={password}
            />
          </StyledField>
        </ConditionalRender>

        <ConditionalRender condition={showOnboarding}>
          <QRCode />
        </ConditionalRender>

        <ConditionalRender condition={!!tfaEnabled && !enforceSaml}>
          <PaddedContent pushBottom="sm">
            <StyledField stacked>
              <VerificationLabel htmlFor="tfaCode">
                {t('twoStepVerification')}{' '}
                <Info tooltipPosition="right">
                  <StyledP>{t('twoStepVerification')}</StyledP>
                  <StyledP>{t('twoStepVerificationCopy')}</StyledP>
                </Info>
              </VerificationLabel>
              <TextInput
                large
                maxLength="6"
                name="tfaCode"
                onChange={(e) => setTfaCode(e.target.value)}
                value={tfaCode}
              />
            </StyledField>
          </PaddedContent>
        </ConditionalRender>

        <ConditionalRender condition={!isCloudflareLink && !!enforceSaml}>
          <PaddedContent pushBottom="md" pushTop="md">
            <Centered>
              <Content>{t('ssoCopy')}</Content>
            </Centered>
          </PaddedContent>
        </ConditionalRender>

        <Footer>
          <div>
            <ConditionalRender condition={!enforceSaml}>
              <Button dataTestId="button-login" disabled={loginDisabled} icon={isLoading && 'spinner'} type="submit">
                {t('login')}
              </Button>
            </ConditionalRender>
            {/* We don't support linking to Cloudflare when SSO is enforced */}
            <ConditionalRender condition={!!enforceSaml && isCloudflareLink}>
              <Centered>
                <Content>
                  <Trans i18nKey="cloudflareLinkUnavailable" ns="loginPage">
                    Your Area 1 organization is SSO enforced and linking to your Cloudflare account is not supported at
                    this time.
                    <br />
                    <br />
                    <Link to="/">Back to the Login page</Link>
                  </Trans>
                </Content>
              </Centered>
            </ConditionalRender>

            <ConditionalRender condition={!!samlEnabled && !urlEmail && tfaEnabled}>
              <ConditionalRender condition={!isCloudflareLink}>
                <Centered disabled={!enforceSaml}>
                  <span />
                  <Button
                    disabled={isLoadingSaml}
                    icon={isLoadingSaml && 'spinner'}
                    fullWidth
                    type="button"
                    onClick={() => {
                      if (onSsoLogin) {
                        onSsoLogin(email);
                      }
                    }}
                  >
                    {t('loginWithSso')}
                  </Button>
                </Centered>
              </ConditionalRender>
            </ConditionalRender>
          </div>

          <ConditionalRender condition={!enforceSaml}>
            <p>
              <Link to={{ pathname: '/users/login/forgot-password', state: { email: encodeURIComponent(email) } }}>
                {t('forgotPassword')}
              </Link>
            </p>
          </ConditionalRender>
        </Footer>
      </ConditionalRender>
    </StyledForm>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------

function validate(email: string, password: string, tfaCode: string, tfaEnabled: boolean) {
  if (!tfaEnabled) return validations.email(email) && validations.presence(email) && validations.presence(password);

  return (
    validations.email(email) &&
    validations.presence(email) &&
    validations.presence(password) &&
    validations.presence(tfaCode) &&
    validations.minLength(6, tfaCode) &&
    validations.maxLength(6, tfaCode)
  );
}

//
// Private components
// -------------------------------------------------------------------------------------------------

const CloudflareButton = stitches('button', {
  alignItems: 'baseline',
  background: '#313131',
  borderColor: 'currentcolor',
  borderRadius: rem(5),
  borderStyle: 'solid',
  borderWidth: 0,
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontFamily:
    '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif',
  fontSize: rem(16),
  fontWeight: 500,
  gap: rem(8),
  justifyContent: 'center',
  padding: `${rem(16)} ${rem(64)}`,
  width: '100%',

  '&:hover': {
    backgroundColor: '#4A4A4A',
  },

  '&:active': {
    backgroundColor: '#3D3D3D',
  },
});

const CloudflareCopy = stitches('div', {
  backgroundColor: '#FFF8E4',
  border: '1px solid #FFCE4B',
  color: '#1d1f20',
  fontFamily:
    '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif',
  fontSize: rem(14),
  userSelect: 'none',
  padding: rem(8),
});

function LinkCloudflareInstructions() {
  const { t } = useTranslation();
  return (
    <CloudflareCopy>
      <p>
        <b>{t('You have successfully authenticated with Cloudflare.')}</b>{' '}
        {t(
          `Now you need to verify your existing Area 1 Security account. After doing so, your accounts will be linked and you will be able to use your Cloudflare account to log into Area 1.`
        )}
      </p>
    </CloudflareCopy>
  );
}

interface SignInWithCloudflareProps {
  disabled?: boolean;
}

function SignInWithCloudflare({ disabled = false }: SignInWithCloudflareProps) {
  const { t } = useTranslation();

  function handleClick() {
    window.location = '/api/cf-auth/login';
  }

  return (
    <CloudflareButton disabled={disabled} onClick={handleClick} type="button">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
        <g clipPath="url(#a)">
          <path
            fill="#F6821F"
            d="m24.156 15.775.181-.626c.216-.744.135-1.433-.227-1.938-.332-.466-.887-.74-1.56-.772l-12.76-.162a.253.253 0 0 1-.2-.107.259.259 0 0 1-.028-.23.34.34 0 0 1 .296-.226l12.878-.164c1.527-.07 3.181-1.31 3.76-2.82l.735-1.92a.452.452 0 0 0 .02-.253 8.386 8.386 0 0 0-16.124-.869 3.763 3.763 0 0 0-2.649-.733 3.778 3.778 0 0 0-3.27 4.688 5.366 5.366 0 0 0-5.15 6.139.25.25 0 0 0 .245.215L23.86 16h.006a.31.31 0 0 0 .291-.225Z"
          />
          <path
            fill="#FBAD41"
            d="M28.407 6.95c-.119 0-.236.003-.353.008a.196.196 0 0 0-.056.012.201.201 0 0 0-.128.136l-.502 1.733c-.216.745-.135 1.432.226 1.938.333.467.888.74 1.562.772l2.72.163a.248.248 0 0 1 .193.106.259.259 0 0 1 .029.231.34.34 0 0 1-.296.226l-2.826.164c-1.534.07-3.188 1.31-3.766 2.821l-.204.534a.151.151 0 0 0 .134.204h9.73a.259.259 0 0 0 .251-.187 6.973 6.973 0 0 0-6.714-8.861Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h35.6v16H0z" />
          </clipPath>
        </defs>
      </svg>
      {t('Sign in with Cloudflare')}
    </CloudflareButton>
  );
}
