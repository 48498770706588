import { Button, Box, CSS, Radio, Stack, Table, Text } from '@a1s/ui';
import React, {
  ChangeEventHandler,
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { RetractParams } from '../../types';

import useRetractEmailsMutation, {
  DestinationTypes,
  RetractEmailMutationResultItem,
  RETRACT_DESTINATIONS,
} from 'screens/Search/lib/hooks/useRetractEmailsMutation';
import { useSearchContext } from 'screens/Search/lib/searchContext';

import { Dialog } from 'ui-new';

import useAccessControl, { permissionTypes, PermissionsType } from 'utils/hooks/useAccessControl';

interface RetractedMessageDetailsType {
  id: string;
  destinationFolder: DestinationTypes;
  status: string;
}

interface RetractionsSummaryType {
  requestedCount: number;
  succeededCount: number;
}

interface Props {
  appearance?: ComponentProps<typeof Button>['appearance'];
  clawbackFeatureEnabled?: boolean;
  disabled?: ComponentProps<typeof Button>['disabled'];
  retractParams: RetractParams[];
}

export const RetractButton = forwardRef<HTMLButtonElement, Props>(function RetractButton(
  { appearance, clawbackFeatureEnabled = false, disabled, retractParams },
  ref
) {
  const { ENTERPRISE_ENABLED } = permissionTypes;
  const { permissions } = useAccessControl([ENTERPRISE_ENABLED] as PermissionsType[]);
  const [enterpriseEnabled] = permissions as boolean[];

  const { userPermitted } = useSearchContext();
  const userCanView = userPermitted && (clawbackFeatureEnabled || enterpriseEnabled);

  const [errorDialogVisible, setErrorDialogVisible] = useState<boolean>(false);
  const [completeDialogVisible, setCompleteDialogVisible] = useState<boolean>(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<RetractedMessageDetailsType[]>([]);
  const [retractDestination, setRetractDestination] = useState<DestinationTypes>('Inbox');
  const [retractionsSummary, setRetractionsSummary] = useState<RetractionsSummaryType>({
    requestedCount: 0,
    succeededCount: 0,
  });

  const { t } = useTranslation('unisearch');

  const sanitizedRetractParams = useMemo(() => {
    return retractParams?.filter((row: RetractParams) => row.clientRecipients?.length !== 0);
  }, [retractParams]);

  const [retract, { loading }] = useRetractEmailsMutation(sanitizedRetractParams, retractDestination);

  // Quick return if the user doesn't have permissions to view this button
  if (!userCanView) return null;

  const closeAlertDialog = () => {
    setCompleteDialogVisible(false);
    resetRetractDestination();
  };

  const openConfirmDialog = () => {
    setConfirmDialogVisible(true);
  };

  const onConfirmClose = () => {
    setConfirmDialogVisible(false);
    resetRetractDestination();
  };

  const closeErrorDialog = () => {
    setErrorDialogVisible(false);
    resetRetractDestination();
    setMessageDetails([]);
  };

  const onConfirm = async () => {
    // Return if we don't have anything to do
    if (sanitizedRetractParams.length === 0) {
      setConfirmDialogVisible(false);
      return;
    }

    try {
      const { data } = await retract();
      const responses = data?.clawback?.responses || [];
      const failures = responses.filter(filterByStatus).map((failure) => ({
        id: failure.messageId,
        destinationFolder: failure.destination,
        status: failure.status,
      }));

      setMessageDetails(failures);
      setRetractionsSummary({
        requestedCount: responses.length,
        succeededCount: responses.length - failures.length,
      });

      if (failures.length > 0) {
        setErrorDialogVisible(true);
      } else {
        setCompleteDialogVisible(true);
      }
    } catch (e: any) {
      let errorDetails;
      try {
        errorDetails = e?.networkError?.result?.responses?.map(
          ({
            destination: destinationFolder,
            message_id: id,
            status,
          }: {
            destination: DestinationTypes;
            // eslint-disable-next-line camelcase
            message_id: string;
            status: number;
          }) => ({
            id,
            destinationFolder,
            status,
          })
        );
        setMessageDetails(errorDetails);
      } catch (parseError) {
        // eslint-disable-next-line no-console
        console.warn('There was a problem with parsing error details.', parseError);
      }

      setErrorDialogVisible(true);
      resetRetractDestination();
    }

    setConfirmDialogVisible(false);
  };

  const handleDestinationChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    const { target } = e;
    if (target) setRetractDestination(target?.value as DestinationTypes);
  };

  const buttonDisabled: boolean | undefined = sanitizedRetractParams.length === 0 || completeDialogVisible || disabled;

  const resetRetractDestination = () => setRetractDestination('Inbox');

  return (
    <>
      <Button appearance={appearance} disabled={buttonDisabled} onPress={openConfirmDialog} ref={ref}>
        <TextDecorator disabled={buttonDisabled}>
          {!completeDialogVisible ? t('retract') : t('retracted')}
        </TextDecorator>
      </Button>

      <Dialog.Confirmation
        confirmButtonText={loading ? t('retracting') : t('retract')}
        message={
          sanitizedRetractParams.length > 0 ? (
            <>
              <Text as="p" color="$gray800" font="sans" size="md" stretch="ultraCondensed" weight="medium">
                {t('selectDestination')}
              </Text>
              <form>
                <Stack>
                  {RETRACT_DESTINATIONS.map((destination: string) => (
                    <Box css={{ label: { fontFamily: '$roboto !important' } }} key={destination}>
                      <Radio
                        block
                        // @ts-expect-error
                        checked={retractDestination === destination}
                        label={t(`components:MailTraceResults.${destination}`)}
                        onChange={handleDestinationChange}
                        value={destination}
                      />
                    </Box>
                  ))}
                </Stack>
              </form>
            </>
          ) : (
            <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
              {t('cannotRetract')}
            </Text>
          )
        }
        handleClose={onConfirmClose}
        handleConfirm={onConfirm}
        visible={confirmDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        message={
          <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
            {t('messageRetracted', { count: retractionsSummary.succeededCount })}
          </Text>
        }
        handleClose={closeAlertDialog}
        visible={completeDialogVisible}
      />

      <Dialog.Alert
        confirmButtonText="OK"
        maxWidth
        message={
          <>
            <Text as="p" font="sans" size="md" stretch="ultraCondensed" weight="medium">
              {retractionsSummary.requestedCount > 0 &&
                t('messageRetractionProblem', {
                  succeeded: retractionsSummary.succeededCount,
                  count: retractionsSummary.requestedCount,
                })}
            </Text>

            <Table>
              <Table.Header>
                <HeaderCell css={{ width: '50%' }}>{t('Message ID')}</HeaderCell>
                <HeaderCell>{t('Destination folder')}</HeaderCell>
                <HeaderCell>{t('Status')}</HeaderCell>
              </Table.Header>

              <Table.Main>
                {/* @ts-expect-error */}
                {messageDetails.length > 0 &&
                  messageDetails.map((message) => (
                    <Table.Row key={message.id}>
                      <DataCell>{message.id}</DataCell>
                      <DataCell>{message.destinationFolder}</DataCell>
                      <DataCell>{message.status}</DataCell>
                    </Table.Row>
                  ))}
              </Table.Main>
            </Table>
          </>
        }
        handleClose={closeErrorDialog}
        visible={errorDialogVisible}
      />
    </>
  );
});

//
// Private components
// -------------------------------------------------------------------------------------------------

interface TextDecoratorType {
  children: ReactElement;
  disabled?: boolean;
}

function TextDecorator({ children, disabled }: TextDecoratorType) {
  if (disabled) return <s>{children}</s>;
  return children;
}

type Cellprops = {
  css?: CSS;
  children: string;
};
function HeaderCell({ css, children }: Cellprops) {
  return (
    <Table.HeaderCell>
      <Text
        as="div"
        color="$gray800"
        css={{ ...css, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        font="sans"
        stretch="ultraCondensed"
        size="sm"
        transform="uppercase"
        weight="semibold"
      >
        {children}
      </Text>
    </Table.HeaderCell>
  );
}

interface DataCellProps {
  border?: ComponentProps<typeof Table.Cell>['border'];
}

function DataCell({ border, children }: PropsWithChildren<DataCellProps>) {
  return (
    <Table.Cell border={border} css={{ lineBreak: 'strict', wordBreak: 'break-all', webkitWordBreak: 'break-word' }}>
      <Text color="$gray600" size="sm" font="sans" stretch="ultraCondensed">
        {children}
      </Text>
    </Table.Cell>
  );
}

//
// Private functions
// -------------------------------------------------------------------------------------------------
function filterByStatus(item: RetractEmailMutationResultItem) {
  return !['OK', 'SUCCESS'].includes(item.status.toUpperCase());
}
